<template>
  <div class="payment-page">
    <div class="payment-page__bg-wrapper payment-page__top">
      <span class="payment-page__bg"></span>
      <div class="payment-page__bg-content">
        <Header />
        <div v-if="status">
          <div v-if="status === 'success'">
            <Title>
              Оплата прошла успешно!
              <template v-slots="subtitle">
                <p v-if="orders.length > 1">Ваши заказы: </p>
                <p v-else>Ваш заказ</p>
                {{ orders.join(', ') }}
                <p v-if="orders.length > 1">Успешно оплачены!</p>
                <p v-else>Успешно оплачен!</p>
              </template>
            </Title>
          </div>
          <div v-else>
            <Title>
              Ошибка оплаты
              <br/>
              <template v-slots="subtitle">
                {{ message }}
              </template>
            </Title>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Title from '@/components/gui/title/Title';

import getInfo from '@/api/getInfo';

import './payment.scss';

export default {
  name: 'Payment',
  components: {
    Header,
    Title,
    Footer: () => import('@/components/uniq/footer/Footer'),
  },
  data() {
    return {
      status: '',
      message: '',
      orders: ''
    }
  },
  async mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const data = await getInfo(this.$route.params.id);

      this.status = data.status
      this.message = data.message
      this.orders = data.order_ids

      if (data.sendGoal) {
        window.ym && window.ym(88990008, 'reachGoal', 'new_pay');
      }

      if (data.status === 'success') {
        this.$store.commit('setCartData', []);
      }
    }
  }
};
</script>
